import i18n from '../../i18n.config';
import { ERRORS } from '../constants';
import { HydraResponse } from '../types/api';
import {
  FullNotification,
  Notification,
  NotificationTranslation
} from '../types/user';
import apiClient from './client';

export type NotificationsResponse = {
  unreadCount: number;
  totalCount: number;
  notifications: Notification[];
};

export const getNotifications = async (
  page: number,
  pageLimit: number
): Promise<NotificationsResponse | Error> => {
  const { statusCode, response } = await apiClient.get<NotificationsResponse>(
    `api/notifications?page=${page}&itemsPerPage=${pageLimit}`
  );

  if (statusCode === 200) {
    return response;
  }

  throw new Error(i18n.t(ERRORS.default));
};

export const markAsRead = async (
  notificationIds: number[]
): Promise<void | Error> => {
  const { statusCode } = await apiClient.post('api/notifications/mark-read', {
    body: JSON.stringify({ notificationIds })
  });

  if (statusCode === 200) return;

  throw new Error(i18n.t(ERRORS.default));
};

export const markAsUnread = async (
  notificationIds: number[]
): Promise<void | Error> => {
  const { statusCode } = await apiClient.post('api/notifications/mark-unread', {
    body: JSON.stringify({ notificationIds })
  });

  if (statusCode === 200) return;

  throw new Error(i18n.t(ERRORS.default));
};

export const removeNotifications = async (
  notificationIds: number[]
): Promise<void | Error> => {
  const { statusCode } = await apiClient.post('api/notifications/delete', {
    body: JSON.stringify({ notificationIds })
  });

  if (statusCode === 200) return;

  throw new Error(i18n.t(ERRORS.default));
};

type SubmitNotificationRequestBody = {
  notificationDate: Date;
  translations: {
    [key: string]: {
      locale: string;
      title: string;
      notification: string;
    };
  };
};

export const addNotification = async (
  requestBody: SubmitNotificationRequestBody
): Promise<void | Error> => {
  const { statusCode } = await apiClient.post('api/notifications', {
    body: JSON.stringify(requestBody)
  });

  if (statusCode === 201) return;

  throw new Error(i18n.t(ERRORS.default));
};

type EditNotificationResponse = {
  translations: { [key: string]: NotificationTranslation };
};

export const editNotification = async (
  id: number,
  requestBody: SubmitNotificationRequestBody
): Promise<EditNotificationResponse | Error> => {
  const { statusCode, response } =
    await apiClient.patch<EditNotificationResponse>(`api/notifications/${id}`, {
      body: JSON.stringify(requestBody),
      headers: {
        ['Content-Type']: 'application/merge-patch+json'
      }
    });

  if (statusCode === 200) return response;

  throw new Error(i18n.t(ERRORS.default));
};

export const deleteNotification = async (id: number): Promise<void | Error> => {
  const { statusCode } = await apiClient.delete(`api/notifications/${id}`);

  if (statusCode === 204) return;

  throw new Error(i18n.t(ERRORS.default));
};

export type AllNotificationsResponse = {
  notifications: FullNotification[];
  totalCount: number;
};

export const deleteMultipleNotifications = async (
  notificationIds: number[]
): Promise<void | Error> => {
  const { statusCode } = await apiClient.post(
    'api/notifications/delete/multiple',
    {
      body: JSON.stringify({ notificationIds })
    }
  );

  if (statusCode === 200) return;

  throw new Error(i18n.t(ERRORS.default));
};

export const getAllNotifications = async (
  page: number,
  itemsPerPage: number
): Promise<AllNotificationsResponse | Error> => {
  const headers = {
    Accept: 'application/ld+json'
  };

  const { statusCode, response } = await apiClient.get<
    HydraResponse<FullNotification>
  >(
    `api/notifications/all?groups[]=translations&itemsPerPage=${itemsPerPage}&page=${page}`,
    {
      headers
    }
  );

  if (statusCode === 200) {
    return {
      notifications: response['hydra:member'],
      totalCount: response['hydra:totalItems']
    };
  }

  throw new Error(i18n.t(ERRORS.default));
};
