import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import MainLayout from '../../components/MainLayout/MainLayout';
import OrderHeader from '../../components/OrderHeader/OrderHeader';
import OrderTable from '../../components/OrderTable/OrderTable';
import { getOrders } from '../../core/api/order';
import { getOrderOverviewColumns, OrderStatus } from '../../core/constants';
import { ItemInOrder, Order } from '../../core/types/product';
import styles from './styles.module.scss';

const PurchaseHistoryPage = () => {
  const { t } = useTranslation('translation');
  const [orders, setOrders] = useState<Order[]>([]);

  const fetchPurchaseHistory = async () => {
    try {
      const fetchedOrders = await getOrders(OrderStatus.completed);

      setOrders(fetchedOrders as Order[]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchPurchaseHistory();
  }, []);

  return (
    <MainLayout>
      <div className={styles.purchaseHistoryPage}>
        <OrderHeader
          title={t('purchase-history.title')}
          backButtonTitle={t('purchase-history.back-btn-title')}
          // titleButtonContent={
          //   <>
          //     <FaIcon faName={FaIcons.printIcon} /> PDF
          //   </>
          // }
        />
        <section className={styles.orders}>
          {orders.map((currentOrder) => {
            const formattedDate = moment(currentOrder.deliveryDate).format(
              'DD/MM/YYYY'
            );
            const titleText = `${t('purchase-history.ordered-on')} ${formattedDate} - ${currentOrder.orderNumber}`;

            return (
              <OrderTable<ItemInOrder>
                key={currentOrder.orderNumber}
                titleView="green"
                titleMainText={titleText}
                tableProps={{
                  columns: getOrderOverviewColumns(t),
                  data: currentOrder.items
                }}
              />
            );
          })}
        </section>
      </div>
    </MainLayout>
  );
};

export default PurchaseHistoryPage;
