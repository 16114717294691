import i18n from '../../i18n.config';
import useProductsStore from '../../store/products';
import {
  ArticlesItems,
  ERRORS,
  ReturnRequestStatus,
  SortOptions
} from '../constants';
import { Product, ProductPromotion, RequestedReturn } from '../types/product';
import apiClient from './client';

const SortOrders = {
  [SortOptions.productsInStock]: '',
  [SortOptions.bestProducts]: ''
};

const AssortmentFilterQueries = {
  [ArticlesItems.fullRange]: '',
  [ArticlesItems.favorite]: 'favorites=true',
  [ArticlesItems.myOrderList]: 'my_order_list=true',
  [ArticlesItems.promotions]: 'promotions=true',
  [ArticlesItems.quickSale]: ''
};

export const getProducts = async (): Promise<Product[] | Error> => {
  const {
    pageLimit,
    currentPage,
    totalCount,
    changeTotalCount,
    filters,
    sorting
  } = useProductsStore.getState();
  const searchKey = isNaN(Number(filters?.search))
    ? `translations.name`
    : 'articleNr';
  const searchQuery = filters.search ? `&${searchKey}=${filters.search}` : '';
  const categoriesQuery = filters.categories.length
    ? `&categories.id=${filters.categories}`
    : '';
  const assortmentFilter = AssortmentFilterQueries[filters.article.value];
  const assortmentFilterQuery = assortmentFilter ? `&${assortmentFilter}` : '';

  const { statusCode, response } = await apiClient.get<Product[]>(
    `api/products?page=${currentPage}&itemsPerPage=${pageLimit}${searchQuery}${categoriesQuery}${SortOrders[sorting]}${assortmentFilterQuery}`
  );

  if (statusCode === 200) {
    const products = response;

    //Temporary
    if (response.length === pageLimit) {
      changeTotalCount(totalCount + pageLimit + 1);
    } else {
      changeTotalCount(0);
    }

    return products;
  } else throw new Error(i18n.t(ERRORS.default));
};

export const getPromotions = async (): Promise<ProductPromotion[] | Error> => {
  const { statusCode, response } =
    await apiClient.get<ProductPromotion[]>('api/promotions');

  if (statusCode === 200) return response;
  else throw new Error(i18n.t(ERRORS.default));
};

type CustomerSheetRequest = {
  search: string;
};

export const getCustomerProducts = async ({
  search
}: CustomerSheetRequest): Promise<Product[] | Error> => {
  const searchKey = isNaN(Number(search)) ? `translations.name` : 'articleNr';
  const query = search ? `&${searchKey}=${search}` : '';

  const { statusCode, response } = await apiClient.get<Product[]>(
    `api/products?my_order_list=true${query}`
  );

  if (statusCode === 200) return response;
  else throw new Error(i18n.t(ERRORS.default));
};

export const requestReturnItems = async ({
  itemsForReturn
}: {
  itemsForReturn: FormData;
}): Promise<void | Error> => {
  const headers = {
    ['Accept']: 'multipart/form-data'
  };

  const { statusCode } = await apiClient.post('api/returns/submit', {
    body: itemsForReturn,
    headers
  });

  if (statusCode === 201) return;
  else throw new Error(i18n.t(ERRORS.default));
};

export const getRequestedReturns = async (
  status: ReturnRequestStatus
): Promise<RequestedReturn[] | Error> => {
  const query = `?status=${status}`;
  const { statusCode, response } = await apiClient.get<RequestedReturn[]>(
    `api/returns${query}`
  );

  if (statusCode === 200) {
    return response;
  } else {
    throw new Error(i18n.t(ERRORS.default));
  }
};

export const removeItemFromReturnRequest = async (
  orderId: number,
  itemId: number
): Promise<void | Error> => {
  const { statusCode } = await apiClient.delete(
    `api/returns/${orderId}/item/${itemId}`
  );

  if (statusCode === 204) return;

  throw new Error(i18n.t(ERRORS.default));
};

export const addToFavorite = async (
  articleNr: string
): Promise<void | Error> => {
  const { statusCode } = await apiClient.post('api/favorite-products/add', {
    body: JSON.stringify({ articleNr })
  });

  if (statusCode === 200) return;
  else {
    throw new Error(i18n.t(ERRORS.default));
  }
};

export const removeFromFavorite = async (
  articleNr: string
): Promise<void | Error> => {
  const { statusCode } = await apiClient.post('api/favorite-products/remove', {
    body: JSON.stringify({ articleNr })
  });

  if (statusCode === 200) return;
  else {
    throw new Error(i18n.t(ERRORS.default));
  }
};
