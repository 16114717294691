import { useTranslation } from 'react-i18next';

import { FaIcons } from '../../core/constants';
import FaIcon from '../FaIcon';
import styles from './styles.module.scss';

const Footer = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'footer' });

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.footerContacts}>
          <div className={styles.footerContact}>
            <div className={styles.contactHead}>
              <img
                src="/images/logo-bleijenberg.svg"
                alt="project logo"
                className={styles.logo}
              />
              <p className={styles.title}>Bleijenberg nv</p>
            </div>
            <div className={styles.contactContent}>
              <p className={styles.text}>
                <span>Ter Stratenweg 46, 2520 Ranst</span>
                <span>T. 03 326 36 00</span>
                <span>
                  E.{' '}
                  <a href="mailto:sales@bleijenberg.be">sales@bleijenberg.be</a>
                </span>
              </p>
              <div className={styles.socials}>
                <a
                  className={styles.socialLink}
                  href="https://www.facebook.com/Bleijenbergnv"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaIcon faName={FaIcons.facebookIcon} />
                </a>
                <a
                  className={styles.socialLink}
                  href="https://www.instagram.com/bleijenbergnv/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaIcon faName={FaIcons.instagramIcon} />
                </a>
                <a
                  className={styles.socialLink}
                  href="https://www.linkedin.com/company/bleijenberg-nv/mycompany/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaIcon faName={FaIcons.linkedInIcon} />
                </a>
                <a
                  href="https://www.youtube.com/@bleijenbergnv"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaIcon faName={FaIcons.youtubeIcon} />
                </a>
              </div>
            </div>
          </div>
          <div className={styles.footerContact}>
            <div className={styles.contactHead}>
              <FaIcon faName={FaIcons.headset} className={styles.contactIcon} />
              <p className={styles.title}>
                <span>{t('helpdesk')}</span>
                <span>T. 03 326 36 00</span>
              </p>
            </div>
            <div className={styles.contactContent}>
              <p className={styles.text}>
                <span>{t('opening-hours-1-line-1')}</span>
                <span>{t('opening-hours-1-line-2')}</span>
                <a href="mailto:helpdesk@bleijenberg.be">
                  helpdesk@bleijenberg.be
                </a>
              </p>
            </div>
          </div>
          <div className={styles.footerContact}>
            <div className={styles.contactHead}>
              <FaIcon
                faName={FaIcons.messagesQuestion}
                className={styles.contactIcon}
              />
              <p className={styles.title}>
                <span>{t('need-help-text')}</span>
                <a href="">{t('need-help-link')}</a>
              </p>
            </div>
            <div className={styles.contactContent}>
              <p className={styles.text}>
                <span>{t('opening-hours-2-line-1')}</span>
                <span>{t('opening-hours-2-line-2')}</span>
              </p>
            </div>
          </div>
        </div>
        <div className={styles.footerLinks}>
          <p>
            {t('copyright')} © 2024&nbsp;&nbsp;—&nbsp;&nbsp;Bleijenberg NV
            <span className={styles.footerLink}>
              <a href="">{t('privacy-policy')}</a>
            </span>
            <span className={styles.footerLink}>
              <a href="">{t('disclaimer')}</a>
            </span>
          </p>
          <p>
            {t('created-by')} <a href="">Liswood & Tache</a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
