import classNames from 'classnames';

import { AvailableLocales } from '../../core/constants';
import styles from './styles.module.scss';

interface IProps {
  locale: AvailableLocales;
  text: string;
  onChange: (locale: AvailableLocales) => void;
  activeLocale: AvailableLocales;
  isDropdown?: boolean;
  disabled?: boolean;
}

const LanguageToggleItem = ({
  locale,
  text,
  activeLocale,
  onChange,
  isDropdown,
  disabled
}: IProps) => {
  if (disabled) return <></>;

  return (
    <button
      type="button"
      className={classNames({
        [styles.languageToggleItem]: !isDropdown,
        [styles.dropdownButton]: isDropdown,
        [styles.selected]: activeLocale === locale
      })}
      onClick={() => onChange(locale)}
    >
      {text}
    </button>
  );
};

export default LanguageToggleItem;
