import classNames from 'classnames';
import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router';

import { getCustomerProducts } from '../../core/api/products';
import { FaIcons, ROUTES } from '../../core/constants';
import { Product } from '../../core/types/product';
import useClickOutside from '../../hooks/useClickOutside';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';
import Button from '../Button/Button';
import Input from '../Input/Input';
import MultiUserSelect from '../MultiUserSelect/MultiUserSelect';
import ProductCard from '../ProductCard/ProductCard';
import styles from './styles.module.scss';

const OrderHeader = ({
  title,
  subTitle,
  backButtonTitle,
  searchPlaceholder = '',
  showSearch,
  selectItem,
  hideUserSelect,
  titleButtonContent,
  titleButtonAction,
  titleLink
}: {
  title: string;
  subTitle?: string;
  backButtonTitle: string;
  searchPlaceholder?: string;
  showSearch?: boolean;
  selectItem?: (product: Product) => void;
  hideUserSelect?: boolean;
  titleButtonContent?: ReactNode;
  titleButtonAction?: () => void;
  titleLink?: {
    text: string;
    url: string;
  };
}) => {
  const navigate = useNavigate();
  const searchRef = useClickOutside(() => setIsSearchActive(false));
  const [customerProducts, setCustomerProducts] = useState<Product[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isSearchActive, setIsSearchActive] = useState<boolean>(false);

  const selectProduct = (product: Product) => {
    selectItem?.(product);
    setIsSearchActive(false);
  };

  const fetchCustomerProducts = async (search: string) => {
    try {
      const fetchedProducts = await getCustomerProducts({ search });

      setCustomerProducts(fetchedProducts as Product[]);
    } catch (error) {
      console.error(error);
      setCustomerProducts([]);
    }
  };

  const runSearch = useDebouncedCallback(
    (search: string) => fetchCustomerProducts(search),
    500
  );

  const handleSearch = (newValue: string) => {
    setIsSearchActive(true);
    setSearchValue(newValue);
    runSearch(newValue);
  };

  return (
    <section
      className={classNames(styles.headSection, {
        [styles.rowView]: hideUserSelect
      })}
    >
      <div className={styles.headButtons}>
        {!hideUserSelect && (
          <>
            <MultiUserSelect view="light" className={styles.desktopSelect} />
            <MultiUserSelect
              view="lg-transparent"
              className={styles.mobileSelect}
            />
          </>
        )}
        <Button
          className={styles.backButton}
          buttonText={backButtonTitle}
          view="secondary"
          onClick={() => navigate(ROUTES.assortment)}
        />
      </div>
      <div className={styles.titleBlock}>
        <h1 className={styles.title}>
          <p className={styles.titleContent}>
            {title}
            {!!titleLink && (
              <a
                href={titleLink.url}
                target="_blank"
                rel="noreferrer"
                className={styles.titleLink}
              >
                {titleLink.text}
              </a>
            )}
          </p>
          {!!titleButtonContent && (
            <Button
              view="transparent"
              className={styles.titleButton}
              onClick={() => titleButtonAction?.()}
            >
              {titleButtonContent}
            </Button>
          )}
        </h1>
        {!!subTitle && <p className={styles.subTitle}>{subTitle}</p>}
        {showSearch && (
          <div className={styles.searchBlock} ref={searchRef}>
            <Input
              name="items-search"
              value={searchValue}
              Icon={FaIcons.searchIcon}
              placeholder={searchPlaceholder}
              onChange={(e) => handleSearch(e.target.value)}
              wrapperClassName={styles.searchWrapper}
            />
            {isSearchActive && (
              <div className={styles.searchResults}>
                {customerProducts.map((product) => (
                  <ProductCard
                    key={product.id}
                    product={product}
                    view="searched"
                    onClickAction={() => selectProduct(product)}
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default OrderHeader;
