import './styles.css';

import DataTable, {
  ConditionalStyles,
  TableColumn,
  TableStyles
} from 'react-data-table-component';

interface IProps<T> {
  columns: TableColumn<T>[];
  data: T[];
  conditionalRowStyles?: ConditionalStyles<T>[];
  customStyles?: TableStyles;
}

const Table = <T,>({
  columns,
  data,
  conditionalRowStyles,
  customStyles
}: IProps<T>) => {
  return (
    <DataTable
      columns={columns}
      data={data}
      conditionalRowStyles={conditionalRowStyles}
      customStyles={customStyles}
    />
  );
};

export default Table;
