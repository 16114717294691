import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { getMarketingFolders } from '../../core/api/marketing';
import { FaIcons } from '../../core/constants';
import { MarketingFolder } from '../../core/types/marketing';
import useClickOutside from '../../hooks/useClickOutside';
import useMarketingStore, {
  GastronoomFolder
} from '../../store/marketingFolders';
import FaIcon from '../FaIcon';
import styles from './styles.module.scss';

const MarketingDropdown = () => {
  const { setFolders } = useMarketingStore();
  const { t } = useTranslation('translation', { keyPrefix: 'menu' });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [marketingFolders, setMarketingFolders] = useState<MarketingFolder[]>(
    []
  );
  const dropdownRef = useClickOutside(() => setIsOpen(false));

  const fetchFolders = async () => {
    try {
      const fetchedFolders = await getMarketingFolders();
      const foldersToSave = [
        GastronoomFolder,
        ...(fetchedFolders as MarketingFolder[])
      ];
      setMarketingFolders(foldersToSave);
      setFolders(foldersToSave);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchFolders();
  }, []);

  return (
    <div className={styles.marketingDropdown} ref={dropdownRef}>
      <button
        className={styles.marketingDropdownBtn}
        onClick={() => setIsOpen(!isOpen)}
      >
        <FaIcon faName={FaIcons.fileArrowDownIcon} /> {t('marketing')}
      </button>
      <div
        className={classNames(styles.dropdownItems, {
          [styles.open]: isOpen
        })}
      >
        {marketingFolders.map((folder) => (
          <Link
            to={`/marketing/${folder.id}`}
            className={styles.marketingFolderItem}
            key={folder.id}
          >
            {' '}
            <FaIcon faName={FaIcons.chevronRight} /> {folder.title}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default MarketingDropdown;
