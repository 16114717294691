import i18n from '../../i18n.config';
import useUserStore from '../../store/user';
import { ERRORS, STORAGE_KEYS } from '../constants';
import { Customer, Invoice } from '../types/user';
import apiClient from './client';

type AuthResponse = {
  token: string;
  message: string;
};

type UserMeResponse = {
  user: {
    id: number;
    email: string;
    admin: boolean;
  };
  unreadCount: number;
  activeCustomer: Customer;
  customers: Customer[];
};

export const getCurrentUser = async () => {
  const { statusCode, response } =
    await apiClient.get<UserMeResponse>('api/users/me');

  if (statusCode === 200) {
    useUserStore.getState().setUnreadNotificationsCount(response.unreadCount);
    useUserStore.getState().setIsAdmin(response.user.admin);
    useUserStore.getState().setEmail(response.user.email);
    return;
  } else {
    throw new Error(i18n.t(ERRORS.default));
  }
};

export const authUser = async (requestData: {
  email: string;
  password: string;
}) => {
  const {
    statusCode,
    response: { message, token }
  } = await apiClient.post<AuthResponse>('login', {
    body: JSON.stringify(requestData)
  });
  if (statusCode === 200) {
    localStorage.setItem(STORAGE_KEYS.auth_token, token);
    return;
  } else if (statusCode === 401) {
    throw new Error(message);
  }
  throw new Error(message || i18n.t(ERRORS.default));
};

export const logout = async () => {
  const { statusCode } = await apiClient.post('api/logout');

  if (statusCode === 200) return;
  else throw new Error(i18n.t(ERRORS.default));
};

export const forgetPassword = async (requestData: { email: string }) => {
  const { statusCode } = await apiClient.post('forgot-password/', {
    body: JSON.stringify(requestData)
  });

  if (statusCode === 204) return;
  throw new Error(i18n.t(ERRORS.default));
};

export const checkResetTokenValidity = async (token: string) => {
  const { statusCode } = await apiClient.get(`forgot-password/${token}`);

  if (statusCode === 200) return;
  throw new Error(i18n.t(ERRORS.default));
};

export const setNewPassword = async ({
  password,
  token
}: {
  password: string;
  token: string;
}) => {
  const { statusCode } = await apiClient.post(`forgot-password/${token}`, {
    body: JSON.stringify({ password })
  });

  if (statusCode === 204) return;

  throw new Error(i18n.t(ERRORS.default));
};

export const fetchMyCustomers = async (
  search?: string | number
): Promise<void | Error> => {
  const searchKey =
    typeof search === 'number' ? 'filterByNumber' : 'filterByName';
  const searchQuery = search ? `?${searchKey}=${search}` : '';
  const { statusCode, response } = await apiClient.get<Customer[]>(
    `api/users/my-customers${searchQuery}`
  );

  if (statusCode === 200) {
    useUserStore.getState().changeUserCustomers(response);
    return;
  }

  throw new Error(i18n.t(ERRORS.default));
};

export const fetchActiveCustomer = async (): Promise<void | Error> => {
  const { statusCode, response } = await apiClient.get<Customer>(
    'api/users/get-active-customer'
  );

  if (statusCode === 200) {
    useUserStore.getState().setActiveCustomer(response);
  }
};

export const setActiveCustomer = async (
  customer: Customer
): Promise<void | Error> => {
  const { statusCode } = await apiClient.post('api/users/set-active-customer', {
    body: JSON.stringify({ customer_id: customer.id })
  });

  if (statusCode === 200) {
    useUserStore.getState().setActiveCustomer(customer);
  }
};

export const getInvoices = async (): Promise<Invoice[] | Error> => {
  const { statusCode, response } =
    await apiClient.get<Invoice[]>('api/invoices');

  if (statusCode === 200) {
    return response;
  }

  throw new Error(i18n.t(ERRORS.default));
};
