import { create } from 'zustand';

import { Customer } from '../core/types/user';

interface UserStore {
  userCustomers: Customer[];
  changeUserCustomers: (customers: Customer[]) => void;
  activeCustomer: Customer | null;
  setActiveCustomer: (customer: Customer) => void;
  unreadNotificationsCount: number;
  setUnreadNotificationsCount: (count: number) => void;
  isAdmin: boolean;
  setIsAdmin: (state: boolean) => void;
  email: string;
  setEmail: (state: string) => void;
}

const useUserStore = create<UserStore>((set) => ({
  userCustomers: [],
  changeUserCustomers: (userCustomers) => set({ userCustomers }),
  activeCustomer: null,
  setActiveCustomer: (activeCustomer) => set({ activeCustomer }),
  unreadNotificationsCount: 0,
  setUnreadNotificationsCount: (unreadNotificationsCount) =>
    set({ unreadNotificationsCount }),
  isAdmin: false,
  setIsAdmin: (isAdmin) => set({ isAdmin }),
  email: '',
  setEmail: (email) => set({ email })
}));

export default useUserStore;
