import i18n from '../../i18n.config';
import { ERRORS } from '../constants';
import {
  MarketingFile,
  MarketingFolder,
  MarketingFullPublication,
  MarketingPublication
} from '../types/marketing';
import apiClient from './client';

export const getMarketingFolders = async (
  folderId?: string
): Promise<MarketingFolder[] | Error> => {
  const query = folderId ? `?folderId=${folderId}` : '';
  const { statusCode, response } = await apiClient.get<MarketingFolder[]>(
    `api/marketing/sub-folders/list${query}`
  );

  if (statusCode === 200) {
    return response;
  }

  throw new Error(i18n.t(ERRORS.default));
};

export const getMarketingFiles = async (
  folderId: string,
  search?: string
): Promise<MarketingFile[] | Error> => {
  const searchQuery = search ? `&searchPhrase=${search}` : '';
  const { statusCode, response } = await apiClient.get<MarketingFile[]>(
    `api/marketing/folder/files?folderId=${folderId}${searchQuery}`
  );

  if (statusCode === 200) {
    return response;
  }

  throw new Error(i18n.t(ERRORS.default));
};

export type PublicationsResponse = {
  count: number;
  pageSize: number;
  results: MarketingPublication[];
};

export const getMarketingPublications = async ({
  search,
  page,
  pageLimit
}: {
  search?: string;
  page: number;
  pageLimit: number;
}): Promise<PublicationsResponse | Error> => {
  const searchQuery = search ? `&searchPhrase=${search}` : '';
  const { statusCode, response } = await apiClient.get<PublicationsResponse>(
    `api/marketing/issuu/publications?page=${page}&itemsPerPage=${pageLimit}${searchQuery}`
  );

  if (statusCode === 200) {
    return response;
  }

  throw new Error(i18n.t(ERRORS.default));
};

export const getFullMarketingPublication = async (
  slug: string
): Promise<MarketingFullPublication | Error> => {
  const { statusCode, response } = await apiClient.get<
    MarketingFullPublication[]
  >(`api/marketing/issuu/publications/${slug}/embed`);

  if (statusCode === 200) {
    return response[0];
  }

  throw new Error(i18n.t(ERRORS.default));
};
