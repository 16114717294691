import classNames from 'classnames';
import { SyntheticEvent, useState } from 'react';

import { addToFavorite, removeFromFavorite } from '../../core/api/products';
import { FaIcons } from '../../core/constants';
import { Product } from '../../core/types/product';
import FaIcon from '../FaIcon';
import styles from './styles.module.scss';

const FavButton = ({
  product,
  className = ''
}: {
  product: Product;
  className?: string;
}) => {
  const [isFavorite, setIsFavorite] = useState<boolean>(product.favorite);
  const handleToggleFavorite = async (event: SyntheticEvent) => {
    event.stopPropagation();
    try {
      const toggleMethod = isFavorite ? removeFromFavorite : addToFavorite;

      await toggleMethod(product.articleNr.toString());

      setIsFavorite(!isFavorite);
    } catch (error) {
      console.error(error);
    }
  };

  const favIcon = isFavorite ? FaIcons.filledHeartIcon : FaIcons.emptyHeartIcon;
  return (
    <button
      className={classNames(
        styles.favButton,
        {
          [styles.favorite]: isFavorite
        },
        className
      )}
      onClick={handleToggleFavorite}
    >
      <FaIcon faName={favIcon} />
    </button>
  );
};

export default FavButton;
