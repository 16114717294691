import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FaIcons } from '../../../core/constants';
import { Product, ProductImage } from '../../../core/types/product';
import Button from '../../Button/Button';
import DownloadOverlay from '../../DownloadOverlay/DownloadOverlay';
import FaIcon from '../../FaIcon';
import FavButton from '../../FavButton/FavButton';
import PriceSection from '../../PriceSection/PriceSection';
import ProductLabels from '../../ProductLabels/ProductLabels';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import styles from './styles.module.scss';

const ProductDetailsModal = ({
  product,
  onClose
}: {
  product: Product;
  onClose: () => void;
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'product-detail' });
  const [activeImage, setActiveImage] = useState<ProductImage>(
    product?.images[0]
  );

  return (
    <ModalWrapper onClose={onClose} bgColor="grey">
      <div className={styles.productDetailsWrapper}>
        <section className={styles.productImageSection}>
          <div className={styles.productImages}>
            <div className={styles.productImageWrapper}>
              <img
                alt="product image"
                className={styles.productImage}
                src={activeImage.preview || 'images/img-placeholder.svg'}
              />
              <DownloadOverlay link={activeImage.original} view="light" />
            </div>
            <div className={styles.imageSwitcher}>
              {product.images.map((image, index) => {
                const isActive = image.original === activeImage.original;
                return (
                  <Button
                    key={image.original}
                    buttonText={index + 1}
                    view={isActive ? 'dark' : 'secondary'}
                    className={styles.imageSwitch}
                    onClick={() => setActiveImage(image)}
                  />
                );
              })}
            </div>
          </div>

          <div className={styles.productButtons}>
            {/* <button className={styles.productDownloadButton}>
              {t('download-tech-sheet')}{' '}
              <FaIcon faName={FaIcons.circleArrowDownIcon} />
            </button> */}
          </div>
        </section>
        <section className={styles.productInfo}>
          <div className={styles.detailsHead}>
            <p className={styles.productTitle}>
              {product.name} {product.description}
            </p>
            <FavButton product={product} className={styles.productFavButton} />
            {/* <button className={styles.productFavButton}>
              <FaIcon faName={FaIcons.emptyHeartIcon} />
            </button> */}
            <ProductLabels
              product={product}
              view="row"
              className={styles.labelsBlock}
            />
          </div>
          <div className={styles.detailContent}>
            <ul className={styles.productStats}>
              <li className={styles.productStatItem}>
                <span className={styles.productStatName}>
                  {t('article-number')}
                </span>
                <span className={styles.productStatValue}>
                  {product.articleNr}
                </span>
              </li>
              <li className={styles.productStatItem}>
                <span className={styles.productStatName}>{t('weight')}</span>
                <span className={styles.productStatValue}>
                  {product.weight}
                </span>
              </li>
              <li className={styles.productStatItem}>
                <span className={styles.productStatName}>{t('points')}</span>
                <span className={styles.productStatValue}>
                  {product.points}PTN
                </span>
              </li>
              <li className={styles.productStatItem}>
                <span className={styles.productStatName}>{t('packaging')}</span>
                <span className={styles.productStatValue}>
                  {product.itemsInPackage} x {product.weight}
                </span>
              </li>
            </ul>
            <div className={styles.priceSectionWrapper}>
              <PriceSection
                className={styles.priceSection}
                product={product}
                view="full"
              />
            </div>
            <div className={styles.deliveryDateWrapper}>
              <p className={styles.deliveryTitle}>
                {t('next-delivery-date-title')}
              </p>
              <p className={styles.deliveryDate}>
                {t('next-delivery-date')}
                <b> 30/05/2024</b>.
              </p>
            </div>
          </div>
          <div className={styles.currentStatus}>
            <FaIcon faName={FaIcons.truckIcon} />
            <p className={styles.statusText}>
              {t('ordered-product-status')}
              <br />
              {t('ordered-product-delivery')} <b>18/06/2024</b>
            </p>
          </div>
        </section>
      </div>
    </ModalWrapper>
  );
};

export default ProductDetailsModal;
