import {
  MarketingFile,
  MarketingPublication
} from '../../core/types/marketing';
import DownloadOverlay from '../DownloadOverlay/DownloadOverlay';
import styles from './styles.module.scss';

interface IProps {
  item: MarketingFile | MarketingPublication;
  selectPublication: () => void;
}

const MarketingFileCard = ({ item, selectPublication }: IProps) => {
  const isFile = !!(item as MarketingFile).type;
  const itemSrc = isFile
    ? (item as MarketingFile).preview
    : (item as MarketingPublication)?.large?.url;
  const title = isFile
    ? (item as MarketingFile).filename
    : (item as MarketingPublication).title;

  return (
    <div
      className={styles.fileCard}
      onClick={() => !isFile && selectPublication()}
    >
      {(item as MarketingFile).type === 'pdf' ? (
        <iframe className={styles.pdf} src={`${itemSrc}#page=1&zoom=1,6,0`} />
      ) : (
        <img src={itemSrc} className={styles.fileImg} />
      )}
      <p className={styles.fileName}>{title}</p>
      {isFile && (
        <DownloadOverlay view="light" link={(item as MarketingFile).original} />
      )}
    </div>
  );
};

export default MarketingFileCard;
